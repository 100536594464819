import React from 'react';
import Masonry from 'react-masonry-css';
import CardSingle from './CardSingle';

export default function GridHeader({ siteTitle, searchHeader }) {

  const getBreakpointColumns = () => {
    if (siteTitle === "product-features" || siteTitle === "Product Methodology") {
      // If the siteTitle is "Product Features", always use 1 column
      return 1;
    } else {
      // Default breakpoint columns configuration for other titles
      return {
        default: 2, // Number of columns at the default breakpoint
        1800: 2,
        1200: 1,    // Number of columns at the 768px breakpoint
      };
    }
  };

  const breakpointColumnsObj = getBreakpointColumns();

  return (
    <div>
    {siteTitle === "product-features" && ( 
             <div className="leading-people-header">
             <img src={process.env.PUBLIC_URL + '/img/readcarefully.svg'} alt="" className="culture-image" />
             <h1>I <em>really love</em>  what I do</h1>
             <p className='leading-people-description'>I’m a builder at heart who loves working on complicated systems and impossible problems. Software is my playground of choice, though I’ve also never been shy with a hot glue gun.  </p>
             </div>
             )}

    {siteTitle === "creating-ideas" && ( 
             <div className="leading-people-header">
             <img src={process.env.PUBLIC_URL + '/img/important.svg'} alt="" className="culture-image" />
             <h1>I love a challenge</h1>
             <p className='leading-people-description'>I’m not the least bit afraid of embarrassing myself in pursuit of a really big problem or a super weird solution.  </p>
       
             <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>

             <div className='additional-notes-header'>
            <h2>Additional Notes:</h2>
            </div>
             
             </div>
             )}

    {siteTitle === "maintenance" && ( 
             <div className="leading-people-header">
             <img src={process.env.PUBLIC_URL + '/img/important.svg'} alt="" className="culture-image" />
             <h1>How I like to be maintained </h1>
             <p className='leading-people-description'>Done right, collaborating gets weird and messy. Below are some tricks for working with or utilizing me as a resource. I'm fully corruptible for a good cup of coffee.</p>
            <br></br>
            
            <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>

            </div>
             )}

    {siteTitle === "important-warnings" && (
    
         <div className="leading-people-header">
         <img src={process.env.PUBLIC_URL + '/img/caution.svg'} alt="" className="culture-image" />
         <h1>I'm grateful for failure</h1>
         <p className='leading-people-description'>Nothing is certain in life accept death, taxes, and failure. Sold as-is.</p>
       

        <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
         

    <div className='additional-notes-header'>
    <h2>Additional Notes:</h2>
    </div>
    </div>
    )}

    {siteTitle === "leading-people" && (
    <div className="leading-people-header">
    <img src={process.env.PUBLIC_URL + '/img/important.svg'} alt="" className="culture-image" />
    <h1>I care deeply about culture</h1>
    <p className='leading-people-description'>Humans are not capital, and output is not a leading indicator of contribution. You don't have to be a jerk to inspire great work. </p>
    <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
            
    
    <div className='additional-notes-header'>
    <h2>Additional Notes:</h2>
    </div>
                </div>
  
)}
    {siteTitle === "assemblage" && (
   <div className="leading-people-header">
     <h1>How my brain works</h1>
     <p className='leading-people-description'>An ego-free look at how my brain ingests, processes, and recalls information. No allen wrench required. </p>
     <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
    <div className='additional-notes-header'>
    <h2>Additional Notes:</h2>
    </div>
    </div>
      )}


{siteTitle === "safety-features" && (
  <div className="leading-people-header">
     <h1>How I find and manifest safety </h1>
     <p className='leading-people-description'>Safety is an absolute right of all humans. It's also critical for tackling hard problems and finding weird solutions. </p>

     <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {searchHeader.map(dataObject => (
          <div key={dataObject.unique_key} className="masonry-item">
            <CardSingle siteTitle={siteTitle} dataObject={dataObject} />
          </div>
        ))}
      </Masonry>
  
   <div className='additional-notes-header'>
    <h2>Additional Notes:</h2>
    </div>
             
         </div>
  )}

{/* {siteTitle === "faqs" && (
     <div className="leading-people-header">
     <h1>Frequently asked questions </h1>
     <p className='leading-people-description'>Questions that I hear often, either from myself or the people in my orbit. I'm not afraid to change my mind when presented with new information. </p>
             
         </div>
  )} */}

</div>
  );
}